require('./range')

var selectedElemKey,
    selectedElem,
    selectedHeight,
    selectedHeightKey,
    selectedKoeff,
    selectedSizeD,
    selectedSizeW,
    resultSize,
    selectedDepth,
    priceDepth = 0,
    selectedShelve,
    priceShelves = 0,
    shelvesCount = 1,
    maxCapacity,
    selectedBalk,
    priceBalk,
    lengthBalk,
    resultPriceBase = 0,
    resultPriceFull = 0,
    priceAdded = 10;

document.addEventListener("DOMContentLoaded", function(){
    if(document.querySelector('.calc-section')){

        var productTypes = document.querySelectorAll('.calc-section__product input'),
            heightRange = document.querySelector('#heightRange'),
            shelvesCountInput = document.querySelector('input[name=shelves-count]');

        //console.log(calcProducts); //массив с данными

        //get calcProducts item by checked item key
        productTypes.forEach((item, key) =>{
            if(key == 0){
                item.checked = true;
                selectedElemKey = key
                selectedElem = item.dataset.product
                selectedKoeff = calcProducts[key]['koeff']
                maxCapacity = calcProducts[key]['capacity']
                getHeight(key);
                getShelves(key);
                if(calcProducts[key]['balk'].length != 0){
                    getBalks(key);
                }
            }
            item.addEventListener('change', function(){
                if(item.checked){
                    selectedElemKey = key
                    selectedElem = item.dataset.product
                    selectedKoeff = calcProducts[key]['koeff']
                    maxCapacity = calcProducts[key]['capacity']
                    getHeight(key);
                    getShelves(key);
                    if(calcProducts[key]['balk'].length != 0){
                        getBalks(key);
                    }
                }
            })

        })

        //get depth height when change range's value
        heightRange.addEventListener('change', function(){
            document.querySelectorAll('#tickmarks li').forEach(item=>{
                if(heightRange.value == item.dataset.value){
                    selectedHeightKey = item.dataset.key
                    selectedHeight = item.dataset.label
                }
            })

            getDepth(selectedElemKey, selectedHeightKey)
        })

        //get selected shelve/balk
        document.querySelector('ul[aria-labelledby=dropdownMenuSize]').addEventListener('change', function(e){
            dropdownMenuSize.innerHTML = e.target.value

            if(e.target.name == 'shelves-size'){
                selectedShelve=e.target.value
                //price
                if(typeof e.target.dataset.price !== 'undefined'){
                    priceShelves = e.target.dataset.price
                }
                //size
                if(typeof e.target.dataset.size !== 'undefined'){
                    resultSize = e.target.dataset.size
                }
            }
            if(e.target.name == 'balks-size'){
                selectedBalk = e.target.value
                priceBalk = e.target.dataset.price
                lengthBalk = e.target.dataset.length
            }

        })

        //get shelves count
        shelvesCountInput.addEventListener('change', function(){
            shelvesCount = shelvesCountInput.value
        })

        //get selected depth
        document.querySelector('ul[aria-labelledby=dropdownMenuDepth]').addEventListener('change', function(e){
            dropdownMenuDepth.innerHTML = e.target.value
            selectedDepth = e.target.value
            priceDepth = e.target.dataset.price

            calcProducts[selectedElemKey]['shelves_size'].forEach(item=>{
                if(item['size_d'] == selectedDepth){
                    selectedShelve = item['value']
                    priceShelves = item['price']
                    selectedSizeD = item['size_d']
                    selectedSizeW = item['size_w']
                    resultSize = selectedSizeD*selectedSizeW
                }
            })

        })

        //set display first step item
        document.querySelector('.calc-section__step').classList.add('d-block');
        displayButtons();

        document.querySelectorAll('.calc-section__btn').forEach(item =>{
            item.addEventListener('click', function(e){
                toggleSteps(e);
            })
        })
    }
});

//----------------functions-------------------//

// get height list by key
function getHeight(key){
    var heightContainer = document.querySelector('#tickmarks'),
        heightList = calcProducts[key]['height'],
        heightListLength = heightList.length - 1,
        heightRange = document.querySelector('#heightRange'),
        heightCount = 0;

    heightContainer.innerHTML = ''
    heightRange.value = '1'

    //generate height items by items in array
    for(var i=0; i<=heightListLength; i++){

        itemsPrice= 0;
        heightList[i]['depths'].forEach(item=>{
            if(item['price']>0){
                itemsPrice++
            }
        })

        if(itemsPrice>0){
            heightCount++
            heightContainer.innerHTML += '<li data-value="'+heightCount+'" data-key="'+heightList.indexOf(heightList[i])+'" data-label="'+Number(heightList[i]['value'])+'"><span>'+Number(heightList[i]['value'])+' см</span></li>'
        }
    }
    heightRange.max = heightCount
    selectedHeight = document.querySelector('#tickmarks li').dataset.label
    selectedHeightKey = document.querySelector('#tickmarks li').dataset.key;

    Range.getPoints();
    Range.rangeChange();

    getDepth(key, selectedHeightKey)
}

//get depth list by height
function getDepth(elemKey, selectedHeightKey){
    var depthList = calcProducts[elemKey]['height'][selectedHeightKey]['depths'],
        depthContainer = document.querySelector('ul[aria-labelledby=dropdownMenuDepth]'),
        depthBtn = document.querySelector('button#dropdownMenuDepth');

    depthContainer.innerHTML = ''

    depthList.forEach((item, key)=>{
        depthContainer.innerHTML += '<li><input type="radio" value="'+item['value']+'" id="depth-'+key+'" name="depth-size" data-price="'+item['price']+'" hidden><label class="dropdown-item" for="depth-'+key+'">'+item['value']+'</label></li>';
    });
    document.querySelectorAll('input[name=depth-size]').forEach((item, key)=>{
        if(key == 0){
            item.checked = true
            depthBtn.innerHTML = item.value
            selectedDepth = item.value
            priceDepth =  item.dataset.price
        }
    })
    if(depthList.length > 1){
        document.querySelector('.depth-card').classList.remove('d-none')
        document.querySelector('.data-depth').classList.remove('d-none')
    }
    else{
        document.querySelector('.depth-card').classList.add('d-none')
        document.querySelector('.data-depth').classList.add('d-none')
    }
}

//get shelves list by key
function getShelves(key){
    var shelvesList = calcProducts[key]['shelves_size'],
        shelvesContainer = document.querySelector('ul[aria-labelledby=dropdownMenuSize]'),
        shelvesBtn = document.querySelector('button#dropdownMenuSize');

    //maxCapacity = calcProducts[key]['capacity_shelves']

    shelvesContainer.innerHTML=''

    if(calcProducts[key]['balk'].length == 0){
        shelvesList.forEach((item, key)=>{
            if(item['price']){
                shelvesContainer.innerHTML += '<li><input type="radio" value="'+item['value']+'" id="shelve-'+key+'" name="shelves-size" data-size="'+item['size_d']+'x'+item['size_w']+'" data-price="'+item['price']+'" hidden><label class="dropdown-item" for="shelve-'+key+'">'+item['value']+'</label></li>'
            }
        });
        document.querySelectorAll('input[name=shelves-size]').forEach((item, key)=>{
            if(key == 0){
                item.checked = true
                shelvesBtn.innerHTML = item.value
                priceShelves = item.dataset.price
                resultSize = item.dataset.size
                selectedShelve = item.value
            }
        })
        selectedBalk = 0;
        priceBalk = 0;

        document.querySelectorAll('.calc-section__title').forEach(item=>{
            if(item.dataset.title == "size-shelves") item.innerHTML = 'Выберите размер полок'
            if(item.dataset.title == "count-shelves") item.innerHTML = 'Выберите колличество полок'
        })
    }
    else{
        selectedShelve = calcProducts[key]['shelves_size'][0]['value']
        priceShelves = calcProducts[key]['shelves_size'][0]['price']
        selectedSizeD = calcProducts[key]['shelves_size'][0]['size_d']
        selectedSizeW = calcProducts[key]['shelves_size'][0]['size_w']
        resultSize = selectedSizeD+'x'+selectedSizeW
        shelvesBtn.innerHTML = ''

    }

}

//get balks list by key
function getBalks(key){
    var balksList = calcProducts[key]['balk'],
        balksContainer = document.querySelector('ul[aria-labelledby=dropdownMenuSize]'),
        balksBtn = document.querySelector('button#dropdownMenuSize');

    balksContainer.innerHTML=''

    balksList.forEach((item, key)=>{
        if(item['price']){
            balksContainer.innerHTML += '<li><input type="radio" value="'+item['name']+'" id="balk-'+key+'" name="balks-size" data-price="'+item['price']+'"data-length="'+item['length']+'" hidden><label class="dropdown-item" for="balk-'+key+'">'+item['name']+'</label></li>'
        }
    });
    document.querySelectorAll('input[name=balks-size]').forEach((item, key)=>{
        if(key == 0){
            item.checked = true
            balksBtn.innerHTML = item.value
            selectedBalk = item.value
            priceBalk = item.dataset.price
            lengthBalk = item.dataset.length
        }
    })

    document.querySelectorAll('.calc-section__title').forEach(item=>{
        if(item.dataset.title == "size-shelves") item.innerHTML = 'Выберите размер балок'
        if(item.dataset.title == "count-shelves") item.innerHTML = 'Выберите колличество ярусов'
    })
    


}

//check key displayed step
function displayButtons(){
    var stepNext = document.querySelector('#stepNext'),
        stepBack = document.querySelector('#stepBack'),
        stepSubmit = document.querySelector('#stepSubmit'),
        stepCaption = document.querySelector('.calc-section__note'),
        calcStep = document.querySelectorAll('.calc-section__step'),
        calcStepLength = calcStep.length - 1;

    calcStep.forEach((item, key)=>{
        if(item.classList.contains("d-block")){

            if(key == 0){
                stepBack.classList.add('d-none');
                stepSubmit.classList.add('d-none');
                stepCaption.classList.add('d-block');
            }

            if(key > 0 && key < calcStepLength){
                stepBack.classList.remove('d-none');
                stepSubmit.classList.add('d-none');
                stepCaption.classList.remove('d-block');
                stepNext.classList.remove('d-none');
            }

            if(key == calcStepLength){
                stepNext.classList.add('d-none');
                stepSubmit.classList.remove('d-none');

                calcTotals();
            }
        }

    })
}

function calcTotals(){

    //calc & show totals
    var resultBaseContainer = document.querySelector('#resultPriceBase'),
        resultFullContainer = document.querySelector('#resultPriceFull'),
        countStoreyShelves;

    resultBaseContainer.innerHTML = ''
    resultFullContainer.innerHTML = ''

    resultPriceBase = (Number(priceShelves)*Number(shelvesCount))+Number(priceDepth)
    if(selectedBalk != 0){
        oneStorey = Number(lengthBalk)/Number(selectedSizeW); //вместимость полок на одном ярусе
        countStoreyShelves = Number(shelvesCount)*oneStorey //кол-во полок для выбранного количество ярусов
        resultPriceBase = (Number(priceShelves)*countStoreyShelves)+(Number(priceDepth)*2)+((Number(shelvesCount)*2)*Number(priceBalk))
    }
    if(selectedKoeff == 1){
        resultPriceBase = (Number(priceShelves)*Number(shelvesCount))+(Number(priceDepth)*4)
    }

    resultPriceFull = resultPriceBase + ((resultPriceBase*priceAdded)/100)

    resultBaseContainer.innerHTML = resultPriceBase
    resultFullContainer.innerHTML = resultPriceFull
    
    // show values on result table
    document.querySelector('#resultWeight').innerHTML = maxCapacity
    document.querySelector('#resultHeight').innerHTML = selectedHeight
    if(selectedDepth != 0){
        document.querySelector('#resultDepth').innerHTML = selectedDepth
    }
    if(selectedBalk != 0){
        document.querySelector('#resultBalk').innerHTML = selectedBalk
    }
    if(selectedBalk != 0) document.querySelector('#resultCount').innerHTML = countStoreyShelves
    else document.querySelector('#resultCount').innerHTML = shelvesCount
    
    document.querySelector('#resultSize').innerHTML = resultSize

    //set form values
    document.querySelector('input[name=order_product]').value = selectedElem;
    document.querySelector('input[name=order_height]').value = selectedHeight;
    document.querySelector('input[name=order_shelves_type]').value = selectedShelve;
    document.querySelector('input[name=order_shelves_count]').value = shelvesCount;
    if(selectedBalk != 0){
        document.querySelector('input[name=order_depth]').value = selectedDepth;
        document.querySelector('input[name=order_balk]').value = selectedBalk;
        document.querySelector('input[name=order_shelves_count]').value = countStoreyShelves;
    }
    document.querySelector('input[name=order_total]').value = resultPriceBase;

}

function toggleSteps(e){
    let elId = e.target.id;
    let activeEl = document.querySelector('.calc-section__step.d-block');

    if(elId == 'stepNext'){

        activeEl.classList.remove('d-block')
        activeEl.nextElementSibling.classList.add('d-block')
        displayButtons();

    }
    if(elId == 'stepBack'){
        activeEl.classList.remove('d-block')
        activeEl.previousElementSibling.classList.add('d-block')
        displayButtons();
    }
}