document.addEventListener('DOMContentLoaded', function(){
    document.querySelectorAll('form').forEach(item=>{
        item.classList.add('needs-validation')
        item.novalidate = true;
    })
    document.querySelectorAll('.input_wrapper').forEach(function (item) {
        if(item.dataset.required){
            item.querySelector('input, textarea').required = true;
        }
    })
    var forms = document.querySelectorAll('.needs-validation')
    
      // Loop over them and prevent submission
      Array.prototype.slice.call(forms)
        .forEach(function (form) {
          form.addEventListener('submit', function (event) {
              console.log(event)
              //event.preventDefault()
            if (!form.checkValidity()) {
              event.preventDefault()
              event.stopPropagation()
            }
    
            form.classList.add('was-validated')
          }, false)
        })
})