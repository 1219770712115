document.addEventListener('DOMContentLoaded', function(){
    var heightRange = document.querySelector('#heightRange');
    
    if(heightRange){
        heightRange.addEventListener('change', function(){ 
            rangeChange();
        });

        getPoints();
        rangeChange();

        document.querySelectorAll('#tickmarks li').forEach(item=>{
            item.addEventListener('click', function(){
                heightRange.value = item.dataset.value  
                rangeChange();
            })
        });

        document.querySelector('#tickmarks').addEventListener('click',function(e){
            heightRange.value = e.target.parentNode.dataset.value
            heightRange.dispatchEvent(new Event('change'))
            rangeChange();
        })
    }
});

function rangeChange(){
    var range = document.querySelector('#heightRange'),
        bgLine = document.querySelector('.calc-section__track-select');

    document.querySelectorAll('#tickmarks li').forEach(item=>{
        if(item.dataset.value == range.value){
            item.style.cssText = "font-size:18px;"
            item.classList.add('tickmarks-li_black')
            bgLine.style.cssText = 'width:'+(100/range.max)*range.value+'%;'
        }
        if(item.dataset.value < range.value){
            item.classList.add('tickmarks-li_black')
            item.style.cssText = ""
        }
        if(item.dataset.value > range.value){
            item.style.cssText = ""
            item.classList.remove('tickmarks-li_black')
        }
        if(range.value == 0){
            bgLine.style.cssText = ""
        }
    });
    document.querySelectorAll('.calc-section__track-points-list div').forEach(item=>{
        if(item.dataset.value == range.value){
            item.classList.add('bg-point')
        }
        if(item.dataset.value < range.value){
            item.classList.add('bg-point')
        }
        if(item.dataset.value > range.value){
            item.classList.remove('bg-point')
        }
    });
}

function getPoints(){
    var range = document.querySelector('#heightRange'),
        points = document.querySelector('.calc-section__track-points-list');

    points.innerHTML=''
    points.style.cssText = 'padding-left:'+100/range.max+'%'
    
    for(var i=1; i<=range.max; i++){
        points.innerHTML += '<div data-value="'+i+'"></div>';
    }
}

window.Range = {
    getPoints,
    rangeChange
}