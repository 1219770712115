document.addEventListener('DOMContentLoaded', function(){

    var priceTotal = document.querySelector('.cart-section__total span');

    //set total on load page
    if(priceTotal) priceTotal.innerHTML = (calcTotalItem()+'').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');


    //remove item
    var remove = document.querySelectorAll('.cart-section__remove');
    remove.forEach(item => {
        item.addEventListener('click', function(){
            item.parentNode.parentNode.removeChild(item.parentNode);

            //remove item from basket
            Product.addItemToBasket(item.closest('.product_id').id, 0, false, true);

            //set total on remove item
            priceTotal.innerHTML = (calcTotalItem()+'').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

            //check have items in list
            if (document.querySelector('.cart-section__list').childElementCount == 0){
                document.querySelector('.cart-section').innerHTML = '<h3 style="color:#55678B; text-align:center;">Корзина пуста</h3>';
            }

        });
    })

    //event on change quantity item
    if (priceTotal) {
        var quantityItem = document.querySelectorAll('input[name=product-quantity]');
        quantityItem.forEach(item =>{
            item.addEventListener('change', function(){
                priceTotal.innerHTML = (calcTotalItem()+'').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
            });
        })
    }

    let basketForm = document.querySelector('#basket_form, #calc_request');
    if (basketForm) basketForm.addEventListener('submit', async function (e) {
        e.preventDefault();

        if (!basketForm.checkValidity()) {
            e.preventDefault()
            e.stopPropagation()
        }
        else {
            var object = {};
            new FormData(basketForm).forEach((value, key) => object[key] = value);
            var json = JSON.stringify(object);

            console.log(object)

            var request = BX.ajax.runComponentAction('warnb:basket', 'checkout', {
                mode:'ajax',
                data: object
            });

            request.then(function(response){
                console.log(response);

                if (response.data.status == 'success') {
                    if (basketForm.id === 'basket_form') {
                        Product.cleanBasket();
                        document.querySelector('.cart-section').innerHTML = '<h3 style="color:#55678B; text-align:center;">Спасибо за заказ!</br><small>В ближайшее время с вами свяжется один из наших менеджеров</small></h3>';
                    }
                    else {
                        basketForm.querySelectorAll('input:not([type="submit"]), textarea').forEach(function (item) {item.value = ''})
                    }

                    Notify('Ваша заявка была отправлена');
                }
                else {
                    Notify(response.data.status == 'error' ? response.data.ERROR : 'Произошла ошибка, повторите пепытку позже. Если ошибка повторится, <a href="/contacts/">свяжитесь с нами</a>');
                }
            });
        }

        basketForm.classList.add('was-validated')
    })
})

//calc total function
function calcTotalItem(){
    calcTotal = 0;
    var cardItem = document.querySelectorAll('.cart-section__item');
    cardItem.forEach(item => {
        var quantityItem = item.querySelector('input[name=product-quantity]').value,
            priceItem = item.dataset.price;

        calcTotal = calcTotal + (Number(quantityItem) * Number(priceItem));
    });
    return calcTotal;
}