document.addEventListener("DOMContentLoaded", function () {

    document.querySelectorAll('.input_wrapper').forEach(function (item) {
        var placeholder = item.dataset.placeholder
        item.querySelector('input, textarea').placeholder = placeholder;
    })
    document.querySelectorAll('.check_wrapper').forEach(function (item) {
        var style = item.dataset.class
        item.querySelector('input[type=checkbox]').classList.add(style);
    })
    
});

