document.addEventListener("DOMContentLoaded", function(){
    if (!location.hash) tabs.init(document.querySelector('.company-tabs'));
})

window.tabs = {
    init: function (element) {
        if (!element) return

        var tabsNav = element.querySelector('.nav-tabs'),
        activeLine = element.querySelector('.nav-tabs hr'),
        tabItems = element.querySelectorAll('.nav-tabs button');

        if (tabsNav) {
            var countItems = tabItems.length,
                tabsCoord = tabsNav.getBoundingClientRect().left;

            activeLine.style.cssText = "width: calc(100% / "+countItems+")";

            tabItems.forEach(item =>{
                console.log(item)
                item.addEventListener('click', function() {
                    window.tabs.navigate(item);
                });
            })
        }
    },
    navigate: function (item) {
        console.log('NAVIGATE');
        var tabsNav = item.closest('.nav-tabs'),
        activeLine = tabsNav.querySelector('hr'),
        tabItems = tabsNav.querySelectorAll('button');

        if (tabsNav) {
            var countItems = tabItems.length,
                tabsCoord = tabsNav.getBoundingClientRect().left;

            activeLine.style.cssText = "width: calc(100% / "+countItems+")";

            var activeCoord = item.getBoundingClientRect().left - tabsCoord;
            activeLine.style.cssText = "width: calc(100% / "+countItems+"); left: "+activeCoord+"px";
        }

        tabItems.forEach(item =>{
            console.log(item)
            item.addEventListener('click', function() {
                window.tabs.navigate(item);
            });
        })
    }
}