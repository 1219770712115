document.addEventListener('DOMContentLoaded', function(){
  if (document.querySelector("#productCarousel")){

    // Initialise Carousel
    const mainCarousel = new Carousel(document.querySelector("#productCarousel"), {
      Dots: false,
      Navigation: false,
    });

    // Thumbnails
    const thumbCarousel = new Carousel(document.querySelector("#productThumb"), {
      Sync: {
        target: mainCarousel,
        friction: 0,
      },
      Dots: false,
      Navigation: true,
      center: true,
      slidesPerPage: 1,
      infinite: false,
    });

    // Customize Fancybox
    Fancybox.bind('[data-fancybox="gallery"]', {
      Carousel: {
        on: {
          change: (that) => {
            mainCarousel.slideTo(mainCarousel.findPageForSlide(that.page), {
              friction: 0,
            });
          },
        },
      },
    });

    setTimeout(function () {
      document.querySelector("#productThumb").style.width = document.querySelector("#productCarousel").clientHeight + 'px'
    }, 100)
  }
})
