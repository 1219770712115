const Swiper = require('../../../node_modules/swiper/swiper-bundle');
window.onload = function () {
    /** Category carousel **/
    {
      if(document.querySelector('.section-slider-element-width')){
        var slideWidth = document.querySelector('.section-slider-element-width').clientWidth;
      }

      document.querySelectorAll('.swiper.containered .swiper-slide').forEach(function (item) {
        item.style.width = slideWidth - 30 + 'px';
      })
      const swiper = new Swiper('.swiper.containered', {
        slidesPerView: 'auto',
        // centeredSlides: true,
        spaceBetween: 30,
        // mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },

        // breakpoints: {
        //   768: {
        //     slidesPerView: 3,
        //   },
        //   1024: {
        //     slidesPerView: 4,
        //   },
        // }
      })
    }

    /** Expand submenu **/
    document.querySelectorAll('.offcanvas-menu__item.offcanvas-menu__item_dropdown').forEach(item => {
      item.addEventListener('click', function (e) {
        console.log('click', this)
        this.classList.toggle('expand')
      })
    })
}