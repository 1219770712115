// import bootstrap from 'bootstrap';
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import Notify from "./notify"
window.Fancybox = Fancybox;
window.Carousel = Carousel;
window.Panzoom = Panzoom;
window.Notify = Notify;
window.bootstrap = require('bootstrap');
// window.Fancybox = require('@fancyapps/ui/dist/fancybox.umd.js')
require('./swiper')
require('./tabs')
require('./product-gallery')
require('./product-quantity')
require('./placeholder')
require('./product')
require('./cart')
require('./calc')
require('./forms-validation')
require('./header-shadow')
require('./modals')
require('./wow')