/**
 * Add to cart scripts
 */

import cookies from './cookies';

/**
 * When click on add to cart button
 */
document.addEventListener('DOMContentLoaded', function () {
	let addToCartBtns = [].slice.call(document.querySelectorAll('.product-addtocard'))
	addToCartBtns.map(function(item) {
		return item.addEventListener('click', function (e) {
			e.preventDefault();
			let productCard = item.closest('.product_id');
			let qntElement = productCard.querySelector('.product-detail_quantity__field input[name="product-quantity"]');
			let quantity = qntElement ? qntElement.value : 1;

			addItemToBasket(productCard.id, quantity);
		})
	})

	/**
	 * When quantity changed
	 */
	let cartQuantity = [].slice.call(document.querySelectorAll('.cart-section__item .product-detail_quantity__input'));
	cartQuantity.map(function(item) {
		return item.addEventListener('change', function () {
			Product.addItemToBasket(item.closest('.product_id').id, item.value, true)
		})
	})

	checkBasket()
})

/**
 * Add product to basket
 * @param {int} itemId Product ID
 * @param {int} count Quantity
 * @param {bool} replace Is replase count?
 * @param {bool} remove Is remove item?
 */
function addItemToBasket (itemId, count, replace = false, remove = false) {
	count = Number(count);
	let basket = cookies.getCookies('vit_basket') || "{}";

	if (basket) {
		try {
			basket = JSON.parse(basket);
		}
		catch (err) {
			console.error(err);
		}

		if (basket[itemId] && !replace) count += Number(basket[itemId]);
	}
	basket[itemId] = count;

	if (remove) delete basket[itemId];

	cookies.setCookies('vit_basket', JSON.stringify(basket), '/', null, null, 31536000);
	cookies.setCookies('vit_items_in_basket', Object.keys(basket).length, '/', null, null, 31536000);

	Notify('Товар добавлен в <a href="/cart/">корзину</a>');

	checkBasket()
}

function checkBasket () {
	let itemsCount = cookies.getCookies('vit_items_in_basket') || 0,
		basketBtn = document.querySelector('.header-btns .header-btns__item[href="/cart/"]')

	if (itemsCount > 0) basketBtn.classList.add('header-btns__item_active')
	else basketBtn.classList.remove('header-btns__item_active')
}


function cleanBasket () {
	cookies.removeCookies('vit_basket');
	cookies.removeCookies('vit_items_in_basket');
}

window.Product = {
	addItemToBasket,
	cleanBasket
}
