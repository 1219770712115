document.addEventListener('DOMContentLoaded', function(){
    var quantContainer = document.querySelectorAll('.product-detail_quantity__field');

    quantContainer.forEach(item => {
        var quant = item.querySelector('input.product-detail_quantity__input'),
            less = item.querySelector('button.product-detail_quantity__less'),
            add = item.querySelector('button.product-detail_quantity__add');

        if (less){

            less.addEventListener('click', function(){
                    console.log(quant.value)
                if (Number(quant.value) > 1){
                    quant.value = Number(quant.value) - 1;
                    quant.dispatchEvent(new Event('change'))
                }
            });
            add.addEventListener('click', function(){
                var maxVal = item.querySelector('input.product-detail_quantity__input').max;
                console.log(quant.value, maxVal, item)

                if(maxVal && quant.value >= maxVal) return;
                quant.value = Number(quant.value) + 1;
                quant.dispatchEvent(new Event('change'))
            });

        }
    });
});