document.addEventListener('DOMContentLoaded', function () {
	var requestModal = document.getElementById('requestModal');
	requestModal.addEventListener('show.bs.modal', function (event) {
		var button = event.relatedTarget
		var title = button ? button.getAttribute('data-bs-title') : 'Консультация'
		requestModal.querySelector('.modal-title').innerText = (title.length ? title : 'Консультация')
	})

	window.openModal = function(){
    var modal = new bootstrap.Modal(document.getElementById('requestModal'))
    modal.show();
	}
})