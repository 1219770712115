function Cookies () {
	this.setCookies = function (key, value, path, domain, expire, maxAge) {
		let cookStr = encodeURIComponent(key)+'='+encodeURIComponent(value);
		if (path) cookStr += '; path='+path;
		if (domain) cookStr += '; domain='+domain;
		if (expire) cookStr += '; expires='+expire;
		if (maxAge) cookStr += '; max-age='+maxAge;

		console.log(cookStr)

		document.cookie = cookStr;
	}

	this.getCookies = function (key) {
		let matches = document.cookie.match(new RegExp(
			"(?:^|; )" + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	this.removeCookies = function (key) {
		this.setCookies(key, null, '/', null, null, 1);
	}
}

export default new Cookies();