//вызов функций при скроллинге
var isScrolling = false;
window.addEventListener("scroll", throttleScroll, false);
function throttleScroll(e) {
    if (isScrolling == false) {
      window.requestAnimationFrame(function() {
        headerShadow(e);
        isScrolling = false;
      });
    }
    isScrolling = true;
}

document.addEventListener("DOMContentLoaded", headerShadow, false);
function headerShadow(){ 
    var scrolled = document.documentElement.scrollTop;

    if(scrolled>5){
        document.querySelector('header').style.cssText = "box-shadow: 0px 0px 20px rgba(51, 51, 51, .06);";
    }
    else{
        document.querySelector('header').style.cssText = ""
    }
}